<template>
	<div>
		<el-dialog
			:title="titleDialog"
			:visible="showDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="45%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row dense>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.establishment_id }">
							<label>Sucursal</label>
							<el-select v-model="form.establishment_id" dusk="establishment_id">
								<el-option
									v-for="option in establishments"
									:key="option.id"
									:value="option.id"
									:label="option.name"
								></el-option>
							</el-select>
							<small
								class="error--text"
								v-if="errors.establishment_id"
								v-text="errors.establishment_id[0]"
							></small>
						</div>
					</v-col>
					<v-col cols="12" xl="12">
						<div class="form-group" :class="{ 'error--text': errors.name }">
							<label>Nombre</label>
							<el-input v-model="form.name"></el-input>
							<small
								class="error--text"
								v-if="errors.name"
								v-text="errors.name[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<div class="form-actions text-right mt-3 mb-3">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Guardando...
						</template>
						<template v-else>
							Guardar
						</template>
					</el-button>
				</div>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'cashes',
			establishments: [],
			valid: false,
			errors: {},
			form: {},
		};
	},
	created() {},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: null,
				establishment_id: null,
				name: null,
			};
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.establishments = response.data.establishments;

				this.form.establishment_id =
					this.establishments.length > 0 ? this.establishments[0].id : null;
			});
		},
		async create() {
			this.titleDialog = this.recordId ? 'Editar caja' : 'Nueva caja';
			await this.getTables();
			if (this.recordId) {
				this.$http.get(`/${this.resource}/record/${this.recordId}`).then((response) => {
					this.form = response.data.data;
				});
			}
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.errors;
					} else {
						this.$message.error(error.response.data.message);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showDialog', false);
			this.initForm();
		},
	},
};
</script>
